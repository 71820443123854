@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: rgb(255, 255, 255);
	background-color: black;
	background: linear-gradient(-45deg, #a2b5fe, #f4a8a9, #14141e, #7e7ec6);
	background-size: 400% 400%;
	/*animation: gradient 30s ease infinite;*/
	height: 100vh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@-webkit-keyframes sharp {
	from {
		-webkit-filter: blur(5px);
		-moz-filter: blur(5px);
		-o-filter: blur(5px);
		-ms-filter: blur(5px);
		filter: blur(5px);
	}
	to {
		-webkit-filter: none;
		-moz-filter: none;
		-o-filter: none;
		-ms-filter: none;
		filter: none;
	}
}

@keyframes sharp {
	from {
		-webkit-filter: blur(5px);
		-moz-filter: blur(5px);
		-o-filter: blur(5px);
		-ms-filter: blur(5px);
		filter: blur(5px);
	}
	to {
		-webkit-filter: none;
		-moz-filter: none;
		-o-filter: none;
		-ms-filter: none;
		filter: none;
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@layer components {
	.card {
		border-radius: theme('borderRadius.lg');
		padding: theme('spacing.6');
		box-shadow: theme('boxShadow.xl');
		backdrop-filter: blur(10px);

		background-color: rgba(0, 0, 0, 0.6);

		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);

		-webkit-animation: sharp 1s; /* Chrome, Safari, Opera */
		animation: sharp 0.9s;
	}
	/* ... */
}
